import classNames from 'classnames'
import React from 'react'
import {Text, TextTypography} from 'wix-ui-tpa/cssVars'
import {useMobileFontOverrides} from '../../../../../../commons/hooks/use-mobile-font-overrides'
import {classes} from './no-events-text.st.css'

export const NoEventsText = props => {
  const {mobileFontOverridesEnabled} = useMobileFontOverrides()
  return (
    <Text
      typography={TextTypography.smallTitle}
      {...props}
      className={classNames(classes.root, {[classes.mobile]: props.mobile && mobileFontOverridesEnabled})}
    />
  )
}
