import {getEventTimeZoneId} from '@wix/wix-events-commons-statics'
import {WithTranslation, withTranslation} from '@wix/yoshi-flow-editor'
import {getLocale} from '../../../../../commons/selectors/environment'
import {internalNavigate} from '../../actions/navigation'
import {DetailedEvent, MemberPageContext} from '../../types/state'
import {connect} from '../connect'
import {MobileOrder as MobileOrderPresentation} from './mobile-order'

export interface MobileOrderProps extends MobileOrderRuntimeProps, WithTranslation {}

interface MobileOrderRuntimeProps {
  event: DetailedEvent
  order: wix.events.ticketing.Order
  locale: string
  timezone: string
  internalNavigate: typeof internalNavigate
}

const mapRuntime = ({state, actions}: MemberPageContext): MobileOrderRuntimeProps => {
  const event = state.navigation.routeParams.event
  return {
    event,
    order: state.navigation.routeParams.order,
    locale: getLocale(state),
    timezone: getEventTimeZoneId(event.event),
    internalNavigate: actions.internalNavigate,
  }
}

export const MobileOrder = connect<{}, MobileOrderRuntimeProps>(mapRuntime)(withTranslation()(MobileOrderPresentation))
