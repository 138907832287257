import classNames from 'classnames'
import React from 'react'
import {Button, ButtonPriority, Spinner, TextButton} from 'wix-ui-tpa/cssVars'
import {useMobileFontOverrides} from '../../../../../../commons/hooks/use-mobile-font-overrides'
import {classes} from './event-link.st.css'
import {EventLinkProps} from '.'

export const EventLink: React.FC<EventLinkProps> = ({primary, secondary, mobile, textButton, loading, ...rest}) => {
  const {mobileFontOverridesEnabled} = useMobileFontOverrides()
  if (textButton) {
    return (
      <TextButton
        {...rest}
        className={classNames(classes.link, {[classes.mobile]: mobile && mobileFontOverridesEnabled})}
      />
    )
  } else {
    let buttonPriority
    let buttonClass
    let spinnerClass

    if (primary) {
      buttonPriority = ButtonPriority.primary
      buttonClass = classes.primaryButton
      spinnerClass = classes.primarySpinner
    } else if (secondary) {
      buttonPriority = ButtonPriority.secondary
      buttonClass = classes.secondaryButton
      spinnerClass = classes.secondarySpinner
    }

    return (
      <div className={classNames(classes.container, {[classes.fullWidth]: mobile})}>
        {loading && (
          <div className={classes.spinnerWrapper}>
            <Spinner diameter={18} className={spinnerClass} />
          </div>
        )}
        <Button
          {...rest}
          className={classNames(buttonClass, {
            [classes.mobile]: mobile && mobileFontOverridesEnabled,
            [classes.hidden]: loading,
          })}
          priority={buttonPriority}
          fullWidth={mobile}
        />
      </div>
    )
  }
}
