import {membersPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {Button, Text} from 'wix-ui-tpa/cssVars'
import s from './error-state.scss'

export const ErrorState = () => {
  const {t} = useTranslation()
  const {isMobile} = useEnvironment()

  const handleClick = () => location.reload()

  return (
    <div data-hook={DH.ERROR_STATE} className={classNames(s.content, {[s.mobile]: isMobile})}>
      <Text className={s.title}>{t('members-page.failed-to-load-events.message')}</Text>
      <Button className={s.button} onClick={handleClick}>
        {t('membersPage_failedToLoadEventsButton')}
      </Button>
    </div>
  )
}
