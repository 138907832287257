import {membersPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import React from 'react'
import {useSettings} from '../../../hooks/settings'
import settingsParams from '../../../settingsParams'
import {Description} from '../styled-components/description'
import {PageTitle} from '../styled-components/page-title'
import s from './header.scss'

export const Header = () => {
  const settings = useSettings()
  const title = settings.get(settingsParams.pageTitle)
  const subtitle = settings.get(settingsParams.description)
  const {isMobile} = useEnvironment()

  return (
    <div className={isMobile ? s.mobile : s.root}>
      <div className={s.title}>
        <PageTitle mobile={isMobile} data-hook={DH.APP_HEADER_TITLE}>
          {title}
        </PageTitle>
      </div>
      <div className={s.subtitle}>
        <Description mobile={isMobile} data-hook={DH.APP_HEADER_DESCRIPTION}>
          {subtitle}
        </Description>
      </div>
    </div>
  )
}
