import React from 'react'
import {ErrorState} from '../error-state'
import {EventsList} from '../events-list'
import {EventsTabs} from '../events-tabs'
import {Header} from '../header'
import {Divider} from '../styled-components/divider'

export const Events = ({error, mobile}: {error: boolean; mobile: boolean}) => {
  if (error) {
    return <ErrorState />
  }

  return (
    <>
      <Header />
      <EventsTabs />
      {!mobile ? <Divider /> : null}
      <EventsList />
    </>
  )
}
