import type {MemberAppProps} from '../../../components/members-page/interface'
import {getToast} from '../../selectors/toast-handler'
import {connect} from '../runtime-context/connect'
import type {ToastHandlerRuntimeProps} from './interfaces'
import {ToastHandler as Presentation} from './toast-handler'

const mapRuntime = ({state, actions: {setToast}}: MemberAppProps): ToastHandlerRuntimeProps => ({
  toast: getToast(state.toastHandler),
  setToast,
})

export const ToastHandler = connect<any, ToastHandlerRuntimeProps>(mapRuntime)(Presentation)
