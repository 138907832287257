import classNames from 'classnames'
import React from 'react'
import {Text, TextTypography} from 'wix-ui-tpa/cssVars'
import {useMobileFontOverrides} from '../../../../../../commons/hooks/use-mobile-font-overrides'
import {classes} from './description.st.css'

export const Description = ({mobile, ...props}) => {
  const {mobileFontOverridesEnabled} = useMobileFontOverrides()
  return (
    <Text
      typography={TextTypography.runningText}
      {...props}
      className={classNames(classes.root, {[classes.mobile]: mobile && mobileFontOverridesEnabled})}
    />
  )
}
