import {WithTranslation, withTranslation} from '@wix/yoshi-flow-editor'
import {isOwnProfile} from '../../selectors/user'
import {DetailedEvent, MemberPageContext} from '../../types/state'
import {connect} from '../connect'
import {OrderDetails as OrderDetailsPresentation} from './order-details'

export interface OrderDetailsProps extends OrderDetailsOwnProps, OrderDetailsRuntimeProps, WithTranslation {}

interface OrderDetailsOwnProps {
  order: wix.events.ticketing.Order
  event: DetailedEvent
}

interface OrderDetailsRuntimeProps {
  isOwner: boolean
}

const mapRuntime = ({state}: MemberPageContext): OrderDetailsRuntimeProps => ({
  isOwner: isOwnProfile(state),
})

export const OrderDetails = connect<OrderDetailsOwnProps, OrderDetailsRuntimeProps>(mapRuntime)(
  withTranslation()(OrderDetailsPresentation),
)
