import {WithTranslation, withTranslation} from '@wix/yoshi-flow-editor'
import {isMobile} from '../../../../../commons/selectors/environment'
import {DetailedEvent, MemberPageContext} from '../../types/state'
import {connect} from '../connect'
import {EventSummary as EventSummaryPresentation} from './event-summary'

export interface EventSummaryProps extends EventSummaryOwnProps, EventSummaryRuntimeProps, WithTranslation {}

interface EventSummaryOwnProps {
  event: DetailedEvent
}

interface EventSummaryRuntimeProps {
  mobile: boolean
}

const mapRuntime = ({state}: MemberPageContext): EventSummaryRuntimeProps => ({
  mobile: isMobile(state),
})

export const EventSummary = connect<EventSummaryOwnProps, EventSummaryRuntimeProps>(mapRuntime)(
  withTranslation()(EventSummaryPresentation),
)
