import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import React, {useEffect} from 'react'
import {Toast, ToastPlacement, ToastSkin} from 'wix-ui-tpa'
import {ToastHandlerProps} from './interfaces'
import s from './toast-handler.scss'

export const ToastHandler = ({toast, setToast}: ToastHandlerProps) => {
  const {t} = useTranslation()
  const {isMobile} = useEnvironment()
  const defaultTimeout = 5000

  useEffect(() => {
    if (toast) {
      const {timeout} = toast
      setTimeout(() => setToast(null), timeout ?? defaultTimeout)
    }
  }, [toast])

  if (toast) {
    const {titleKey, skin, ...rest} = toast

    return (
      <div className={s.toastContainer}>
        <Toast
          placement={isMobile ? ToastPlacement.bottomFullWidth : undefined}
          skin={skin as ToastSkin}
          shouldShowCloseButton={true}
          onClose={() => setToast(null)}
          {...rest}
        >
          {t(titleKey)}
        </Toast>
      </div>
    )
  }
  return null
}
