import {isOwnProfile} from '../../../selectors/user'
import {DetailedEvent, MemberPageContext} from '../../../types/state'
import {connect} from '../../connect'
import {OrderWithSeating as OrderWithSeatingPresentation} from './order-with-seating'

export interface OrderWithSeatingProps extends OrderWithSeatingOwnProps, OrderWithSeatingRuntimeProps {}

interface OrderWithSeatingOwnProps {
  order: wix.events.ticketing.Order
  event: DetailedEvent
}

interface OrderWithSeatingRuntimeProps {
  isOwner: boolean
}

const mapRuntime = ({state}: MemberPageContext): OrderWithSeatingRuntimeProps => ({
  isOwner: isOwnProfile(state),
})

export const OrderWithSeating = connect<OrderWithSeatingOwnProps, OrderWithSeatingRuntimeProps>(mapRuntime)(
  OrderWithSeatingPresentation,
)
