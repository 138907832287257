import {membersPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import React from 'react'
import {Routes} from '../../../../../../commons/enums'
import {EventLink} from '../../styled-components/event-link'
import {GoToOrderButtonProps} from './index'

export const GoToOrderButton = ({event, internalNavigate, t}: GoToOrderButtonProps) => (
  <EventLink
    secondary={true}
    mobile={true}
    as="a"
    onClick={() => internalNavigate(Routes.ORDER_LIST, {event})}
    data-hook={DH.BUTTON_SHOW_ORDERS}
  >
    {t('events.buttons.order-details')}
  </EventLink>
)
