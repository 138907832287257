import {
  ISiteTextPreset,
  IStyleParam,
  IWixStyleFont,
  StyleParamType,
  createStylesParams,
  wixColorParam,
  wixFontParam,
} from '@wix/tpa-settings'
import {GetDefaultValueProps} from '../widget/stylesParams'

const getDefaultFont = (name: keyof ISiteTextPreset, options: Partial<IWixStyleFont> = {}) => {
  return ({textPresets}: {textPresets: ISiteTextPreset}) => {
    const {value: _, ...font} = wixFontParam(name, options)({textPresets})
    return font as IWixStyleFont
  }
}

const getMembersDefaultV2Font = (
  params: GetDefaultValueProps,
  fontParam: IStyleParam<StyleParamType.Font>,
  mobileFontSize: number,
  preset: keyof ISiteTextPreset,
) => {
  const {isMobile, getStyleParamValue} = params
  const fontValue = getStyleParamValue(fontParam)

  if (isMobile) {
    const {value: _value, preset: _preset, ...rest} = fontValue
    return getDefaultFont(preset, {...rest, size: mobileFontSize})(params)
  }

  return fontValue
}

type IStyleParamsV1 = {
  pageTitleFont: StyleParamType.Font
  descriptionFont: StyleParamType.Font
  eventTextFont: StyleParamType.Font
  eventLinkFont: StyleParamType.Font
  eventLinkButtonFont: StyleParamType.Font
  noEventsTextFont: StyleParamType.Font
}

type IStyleParamsV2 = {
  pageTitleFontV2: StyleParamType.Font
  descriptionFontV2: StyleParamType.Font
  eventTextFontV2: StyleParamType.Font
  eventLinkFontV2: StyleParamType.Font
  eventLinkButtonFontV2: StyleParamType.Font
  noEventsTextFontV2: StyleParamType.Font
}

type IStyleParams = {
  selectedTabColor: StyleParamType.Color
  tabsColor: StyleParamType.Color
  tabsFont: StyleParamType.Font
  pageTitleColor: StyleParamType.Color
  descriptionColor: StyleParamType.Color
  eventTextColor: StyleParamType.Color
  eventLinkColor: StyleParamType.Color
  eventLinkButtonColor: StyleParamType.Color
  eventLinkButtonBorderColor: StyleParamType.Color
  eventLinkButtonBorderRadius: StyleParamType.Number
  eventLinkButtonBorderWidth: StyleParamType.Number
  noEventsTextColor: StyleParamType.Color
  noEventsButtonFont: StyleParamType.Font
  noEventsButtonColor: StyleParamType.Color
  noEventsButtonBorderRadius: StyleParamType.Number
  noEventsButtonBorderColor: StyleParamType.Color
  noEventsButtonBorderWidth: StyleParamType.Number
  backgroundColor: StyleParamType.Color
  backgroundBorderColor: StyleParamType.Color
  backgroundBorderWidth: StyleParamType.Number
  dividerColor: StyleParamType.Color
  dividerWidth: StyleParamType.Number
} & IStyleParamsV1 &
  IStyleParamsV2

const stylesParamsV1 = createStylesParams<IStyleParamsV1>({
  pageTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Page-title', {
      size: 28,
    }),
  },
  descriptionFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  eventTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  eventLinkFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  eventLinkButtonFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  noEventsTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 24,
    }),
  },
})

const stylesParamsV2 = createStylesParams<IStyleParamsV2>({
  pageTitleFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params => getMembersDefaultV2Font(params, stylesParamsV1.pageTitleFont, 20, 'Page-title'),
  },
  descriptionFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params => getMembersDefaultV2Font(params, stylesParamsV1.descriptionFont, 16, 'Body-M'),
  },
  eventTextFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params => getMembersDefaultV2Font(params, stylesParamsV1.eventTextFont, 16, 'Body-M'),
  },
  eventLinkFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params => getMembersDefaultV2Font(params, stylesParamsV1.eventLinkFont, 16, 'Body-M'),
  },
  eventLinkButtonFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params => getMembersDefaultV2Font(params, stylesParamsV1.eventLinkButtonFont, 16, 'Body-M'),
  },
  noEventsTextFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params => getMembersDefaultV2Font(params, stylesParamsV1.noEventsTextFont, 24, 'Body-M'),
  },
})

export default createStylesParams<IStyleParams>({
  ...stylesParamsV1,
  ...stylesParamsV2,
  selectedTabColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  tabsColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  tabsFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  pageTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  descriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  eventTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  eventLinkColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  eventLinkButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  eventLinkButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  eventLinkButtonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  eventLinkButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  noEventsTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  noEventsButtonFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  noEventsButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  noEventsButtonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  noEventsButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  noEventsButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  backgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
  backgroundBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  backgroundBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  dividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  dividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
})
